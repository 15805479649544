import {
  Typography,
  AppBar,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Switch,
  FormControlLabel,
  Stack,
  Button,
  Box,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { FC, Suspense, useEffect, useState } from "react";
import "./ImageModal.scss";
import CloseIcon from "@mui/icons-material/Close";
import { ImageSummary } from "../../models/ImageSummary";
import GradeCard from "../GradeCard/GradeCard";
import { baseUrl } from '../../util/config';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { getJpegImage } from "../../util/util";
import axios from "axios";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import NoImageFound from "../../assets/no_image_found.jpg";

interface ImageModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  image_summary: ImageSummary;
  fromDate: string;
  toDate: string;
  mill_id: string
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const ImageModal: FC<ImageModalProps> = ({ open, setOpen, image_summary, fromDate, toDate, mill_id }) => {
  const [checked, setChecked] = useState(true);
  const [imageIndex, setImageIndex] = useState(0);
  const [imageList, setImageList] = useState([]);
  const [rawImageList, setRawImageList] = useState([]);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const handleClose = () => {
    setOpen(false);
    setChecked(true);
    setImageList([])
  };
  console.log(image_summary);

  useEffect(() => {
    if (open) {
      axios.get(`${baseUrl}/api/image/gradedImages?mill_id=${mill_id}&fromDate=${fromDate}&toDate=${toDate}&truckId=${image_summary.truckId}`).then(res => {
        console.log(res.data);
        setImageIndex(0);
        if (res.data) {
          if (res.data.mask) {
            setImageList(res.data.mask)
          }
          if (res.data.raw) {
            setRawImageList(res.data.raw)
          }
        }
      });
    }
  }, [open])

  const imageButtonStyle = {
    minWidth: 0,
    padding: 0
  }

  const onForwardClick = () => {
    if (imageIndex + 1 === imageList.length) {
      setImageIndex(0)
    } else {
      setImageIndex(imageIndex + 1)
    }
  }

  const onBackwardClick = () => {
    if (imageIndex - 1 === -1) {
      setImageIndex(imageList.length - 1);
    } else {
      setImageIndex(imageIndex - 1)
    }
  }


  return (
    <div className="ImageModal">
      {open ? (
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar
            sx={{ position: "relative" }}
            style={{ background: "#1D2C0B" }}
          >
            <Toolbar>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Truck Id: {image_summary.truckId}
              </Typography>
              <IconButton
                color="inherit"
                edge="end"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>
          <GradeCard image_summary={image_summary}
            show_small={true}
            show_oer={true} show_image_count={true} image_count={imageList.length} />
          <FormControlLabel
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginRight: "20px",
            }}
            control={
              <Switch
                checked={checked}
                onChange={handleChange}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Masked Image"
          />
          {imageList.length !== 0 ?

            <Stack direction={'row'} spacing={1}>
              <Button style={imageButtonStyle} onClick={onBackwardClick}><ChevronLeftIcon></ChevronLeftIcon></Button>
              {checked ? (
                <Suspense>
                  <TransformWrapper>
                    <TransformComponent
                      wrapperStyle={{ margin: "auto", border: "2px solid black" }}
                    >

                      <Box
                        component="img"
                        sx={{
                          height: "70vh",
                          objectFit: "contain",
                        }}
                        alt=""
                        onError={(e: any) => {
                          e.target.onError = null; // prevents looping
                          e.target.src = NoImageFound;
                        }}//"this.onerror=null; this.src='Default.jpg'"
                        src={imageList[imageIndex]}
                      />
                    </TransformComponent>
                  </TransformWrapper>

                </Suspense>
                // <img
                //   key={`${checked}`}
                //   style={{
                //     height: "70vh",
                //     objectFit: "contain",
                //   }}
                //   //src={`${baseUrl}/images_processed/${getJpegImage(image_summary.filename, false)}`}
                //   src={imageList[imageIndex]}
                //   alt={image_summary.filename}
                // />
              ) : (
                <Suspense>
                  <TransformWrapper>
                    <TransformComponent
                      wrapperStyle={{ margin: "auto", border: "2px solid black" }}
                    >
                      <Box
                        component="img"
                        sx={{
                          height: "70vh",
                          objectFit: "contain",
                        }}
                        alt=""
                        onError={(e: any) => {
                          e.target.onError = null; // prevents looping
                          e.target.src = NoImageFound;
                        }}
                        src={rawImageList[imageIndex]}
                      />
                    </TransformComponent>
                  </TransformWrapper>
                </Suspense>
                // <img
                //   key={`${checked}`}
                //   style={{
                //     height: "70vh",
                //     objectFit: "contain",
                //   }}
                //   //src={`${baseUrl}/images_raw/${getJpegImage(image_summary.filename, true)}`}
                //   src='https://cerespalmgrader.blob.core.windows.net/millgraderdev/E9849EC2/0F9FBB56-7ABA-47CC-9F3E-B2C90DC10D70/SAMPLE_AI_OUTPUT1/mask/FFB_20220428_1303421651122222815.webp?st=2022-08-10T02%3A06%3A30Z&se=2022-09-10T02%3A06%3A30Z&sp=r&sv=2018-03-28&sr=b&sig=aMRcI1GhwZkv9aLzao4lG05nH3hOfrEuygwm7VgdAzc%3D'
                //   alt={image_summary.filename}
                // />
              )}
              <Button style={imageButtonStyle} onClick={onForwardClick}><ChevronRightIcon></ChevronRightIcon></Button>
            </Stack>
            : null}
        </Dialog>
      ) : null}
    </div>
  );
};

export default ImageModal;
