import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePagination from "@mui/material/TablePagination";
import ImageModal from "../../ImageModal/ImageModal";
import { Button, FormControl, MenuItem, Select } from "@mui/material";
import axios from "axios";
import { baseUrl } from "../../../util/config";
export const PlatformTableComponent = ({ type, rows, fromDate, toDate, mill }) => {
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [report, setReport] = useState('platform');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [rows, setRows] = useState([]);
  const [selectedRow, setSelectedRow] = useState();

  const [open, setOpen] = useState(false);

  // useEffect(() => {
  //   if (mill && fromDate && report) {
  //     fetchTableInfo();
  //   }
  // }, [mill, fromDate, report]);

  // const fetchTableInfo = async () => {
  //   const tableData = await axios.get(
  //     `${baseUrl}/api/dashboard/platform-report-table?mill_id=${mill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=${report}`
  //   );
  //   setRows(tableData.data);
  // };

  const handleChange = (event) => {
    setReport(event.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleImageModalClose = () => {
    setImageModalIsOpen(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };


  const accepted_files = ['FFB_20220426_1200191650945619581.webp', 'FFB_20220426_1439511650955191645.webp', 'FFB_20220426_1649041650962944336.webp', 'FFB_20220426_1658471650963527219.webp', 'FFB_20220426_1724111650965051718.webp', 'FFB_20220426_1745211650966321416.webp', 'FFB_20220427_0934481651023288768.webp', 'FFB_20220427_1059391651028379126.webp', 'FFB_20220427_1204421651032282719.webp', 'FFB_20220427_1700321651050032601.webp', 'FFB_20220428_0831281651105888789.webp', 'FFB_20220428_1014561651112096374.webp', 'FFB_20220428_1041461651113706353.webp', 'FFB_20220428_1052421651114362069.webp', 'FFB_20220428_1207191651118839424.webp']

  const columns = [
    {
      id: "truckId",
      label: "Source",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "total_fruit_count",
      label: "Total FFBs",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "totalWeight",
      label: "Total Weight (t)",
      format: (value) => value.toFixed(2),
    },
    {
      id: "unripe_count",
      label: "Unripe",
      format: (value) => (value ?? 0).toLocaleString("en-US"),
    },
    {
      id: "underripe_count",
      label: "Under-ripe",
      format: (value) => (value ?? 0).toLocaleString("en-US"),
    },
    {
      id: "ripe_count",
      label: "Ripe",
      format: (value) => (value ?? 0).toLocaleString("en-US"),
    },
    {
      id: "overripe_count",
      label: "Over-ripe",
      format: (value) => (value ?? 0).toLocaleString("en-US"),
    },
    {
      id: "empty_count",
      label: "Empty",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "partheno_count",
      label: "Partheno",
      format: (value) => value.toLocaleString("en-US"),
    },
    {
      id: "small_count",
      label: "Small",
      format: (value) => value.toLocaleString("en-US"),
    }

    // {
    //   id: "medium",
    //   label: "Medium",
    //   format: (value) => value.toLocaleString("en-US"),
    // },
    // {
    //   id: "large",
    //   label: "Large",
    //   format: (value) => value.toLocaleString("en-US"),
    // },
  ];

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#1D2C0B",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableCell2 = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#BFBFBF",
      color: theme.palette.common.black,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <FormControl sx={{ m: 1, minWidth: 300 }}>
        {/* <InputLabel id="controlled-open-select-label">Report</InputLabel> */}
        <Select
          labelId="controlled-open-select-label"
          id="controlled-open-select"
          open={open}
          onClose={handleClose}
          onOpen={handleOpen}
          value={report}
          onChange={handleChange}
          sx={{ fontSize: 28, fontWeight: 'bold' }}
        >
          <MenuItem value={'platform'}>Platform Report</MenuItem>
          {/* <MenuItem value={'conveyor'}>Conveyor Report</MenuItem> */}
        </Select>
      </FormControl>
      <ImageModal
        open={imageModalIsOpen}
        setOpen={setImageModalIsOpen}
        image_summary={selectedRow}
        fromDate={fromDate}
        toDate={toDate}
        mill_id={mill ? mill.mill_id : ''}
      />
      <TableContainer component={Paper} sx={{ borderRadius: "16px" }}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center" colSpan={1}>
                Source
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={1}>
                Total FFBs
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={1}>
                Total Weight (t)
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={7}>
                Categories
              </StyledTableCell>
              <StyledTableCell align="center" colSpan={1}>
                Media
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell2 align="center"></StyledTableCell2>
              <StyledTableCell2 align="center"></StyledTableCell2>
              <StyledTableCell2 align="center"></StyledTableCell2>
              <StyledTableCell2 align="center">Unripe</StyledTableCell2>
              <StyledTableCell2 align="center">Under-ripe</StyledTableCell2>
              <StyledTableCell2 align="center">Ripe</StyledTableCell2>
              <StyledTableCell2 align="center">Over-ripe</StyledTableCell2>
              <StyledTableCell2 align="center">Empty</StyledTableCell2>
              <StyledTableCell2 align="center">Partheno</StyledTableCell2>
              <StyledTableCell2 align="center">Small</StyledTableCell2>
              {/* <StyledTableCell2 align="center">Medium</StyledTableCell2>
              <StyledTableCell2 align="center">Large</StyledTableCell2> */}
              <StyledTableCell2></StyledTableCell2>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row) => {
                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.truckId}
                  >
                    {columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <StyledTableCell
                          key={row.truckId + column.id}
                          align={"center"}
                        >
                          {column.format && typeof value === "number"
                            ? column.format(value)
                            : value}
                        </StyledTableCell>
                      );
                    })}
                    <StyledTableCell
                      key={row.truckId + "ViewIMage"}
                      align="center"
                    >
                      <Button
                        key={row.truckId + "ViewIMageButton"}
                        onClick={() => {
                          setSelectedRow(row);
                          setImageModalIsOpen(true);
                        }}
                      >
                        View
                      </Button>
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
