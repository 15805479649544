import { Grid, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { SimpleCard } from "../../common/SimpleCard"
import GradeDistributionCard from "../../GradeDistributionCard/GradeDistributionCard";

export const ConveyorReportComponent = ({
  conveyorChart,
  summary,
  oer
}) => {

  const [conveyorOerChart, setConveyorOerChart] = useState({});
  const [avgOerValue, setAvgOerValue] = useState(0);
  const [avgOprationTime, setAvgOprationTime] = useState(0);
  const [totalOperationTime, setTotalOperationTime] = useState(0);

  useEffect(() => {
    if (conveyorChart) {
      console.log(conveyorChart)
      setChartSeries(conveyorChart.chartSeries ?? []);
      setChartOptions({
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Distributions - Conveyor',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        xaxis: {
          categories: conveyorChart.chartOptions ?? [],
          type: "datetime",
          labels: {
            format: 'dd/MM/yyyy'
          }
        },
        yaxis: [
          {
            seriesName: 'Ripe',
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#008FFB',
              }
            },
            title: {
              text: "FFB Grade count",
              style: {
                color: '#008FFB',
              }
            },
            tooltip: {
              enabled: false
            }
          },
          {
            seriesName: 'Ripe',
            show: false
          },
          {
            seriesName: 'Ripe',
            show: false
          },
          {
            seriesName: 'Ripe',
            show: false
          },
          {
            seriesName: 'Ripe',
            show: false
          },
          {
            opposite: true,
            seriesName: 'oer_value',
            show: oer,
            axisTicks: {
              show: true
            },
            axisBorder: {
              show: true,
            },
            title: {
              text: "OER Trend",
              style: {
                color: '#424242',
              }
            }
          },
        ]
      })
    }
  }, [conveyorChart])
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  //   const [chartSeries, setChartSeries] = useState([{
  //       name: "Ripe",
  //       data: [19, 41, 35, 51, 49, 69, 40]
  //   },
  //   {
  //       name: "UnRipe",
  //       data: [100, 44, 35, 42, 49, 74, 90]
  //   },
  //   {
  //     name: "OverRipe",
  //     data: [19, 41, 35, 51, 49, 69, 77]
  // }
  // ]);
  //   const [chartOptions, setChartOptions] = useState({
  //     chart: {
  //       height: 350,
  //       type: 'line',
  //       zoom: {
  //         enabled: false
  //       }
  //     },
  //     dataLabels: {
  //       enabled: false
  //     },
  //     stroke: {
  //       curve: 'straight'
  //     },
  //     title: {
  //       text: 'Number of FFBs - Conveyor',
  //       align: 'left'
  //     },
  //     grid: {
  //       row: {
  //         colors: ['transparent'], 
  //         opacity: 0.5
  //       },
  //     },
  //     xaxis: {
  //       categories: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  //     }
  //   });

  return (
    <>
      <Typography variant="h5" component="div">
        Conveyor
      </Typography>
      <Stack direction={'column'} spacing={2}>
        <Stack direction={'row'}>
          <Grid container spacing={2}>
            <Grid item sm={4.5}>
              <SimpleCard
                border={0.1}
                fontSize={18}
                cardColor='#fff'
                titleText='Loose fruit volume (mᶟ)'
                value={summary && summary.loose_fruit_volume ? summary.loose_fruit_volume : 0}>
              </SimpleCard>
            </Grid>
            {/* <Grid item sm={6}>
              <SimpleCard
                border={0.1}
                fontSize={18}
                cardColor='#fff'
                titleText='Total FFB Count'
                value={summary && summary.total_fruit_count ? summary.total_fruit_count : 0}>
              </SimpleCard>
            </Grid> */}
            <Grid item sm={4.5}>
              <SimpleCard
                border={0.1}
                fontSize={18}
                cardColor='#fff'
                titleText='Operation time (Hrs)'
                value={summary && summary.total_operation_minutes ? (summary.total_operation_minutes / 60).toFixed(1) : 0}>
              </SimpleCard>
            </Grid>
            <Grid item sm={3}>
              <SimpleCard
                border={0.1}
                fontSize={18}
                cardColor='#fff'
                titleText='Avg OER'
                value={summary && summary.avg_oer_value ? summary.avg_oer_value.toFixed(1) : 0}>
              </SimpleCard>
            </Grid>
          </Grid>
        </Stack>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ maxWidth: "100%" }}>
              <Typography
                sx={{ fontSize: "24", fontWeight: "bold" }}
                color="text.secondary"
                gutterBottom
              >
                {"Grading Distribution (%)"}
              </Typography>
            </div>
            <GradeDistributionCard
              image_summary={summary}
              cardColor={"#DEE7CC"}
              fontSize={18}
              titleText={"(%)"}
            />
          </Grid>

        </Grid>
        <Grid container>
          <Grid item sm={12}>
            <ReactApexChart options={chartOptions} series={chartSeries} type='line'></ReactApexChart>
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}