import { Card, CardContent, Stack } from '@mui/material';
import { MillSelectComponent } from '../../components/MillSelectComponent';
import { PlatformImageComponent } from '../../components/overview/platform/platformImageComponent';
import { FFBOverviewChart } from '../../components/overview/ffbOverviewChart';

import { MillSummaryComponent } from '../../components/summary/MillSummaryComponent';
import './millOverview.scss';
import { useEffect, useState } from "react";
import axios from 'axios';
import { baseUrl } from '../../util/config';
import { processDateRangeValue } from '../../util/util';
import { useLocation } from 'react-router-dom';
import { CONVEYOR, PLATFORM } from '../../constants/Constants';
import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner';
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

export const MillOverview = () => {
    const location = useLocation();
    const [overviewType, setOverviewType] = useState('platform');
    const [selectedMill, setSelectedMill] = useState()
    const [fromDate, setFromDate] = useState(processDateRangeValue(new Date(), new Date()).fromDate);
    const [summary, setSummary] = useState();
    const [platformChart, setPlatformChart] = useState();
    const [conveyorChart, setConveyorChart] = useState();
    const [chartData, setChartData] = useState();
    const [isLoading, setIsLoading] = useState(false);

    let promiseList = [];

    useEffect(() => {
        if (!selectedMill && location.state && location.state.selectedMill) {
            setSelectedMill(location.state.selectedMill);
        }
        if (selectedMill && fromDate) {
            setIsLoading(true);
            // apiCall();
            promiseList = [(axios.get(`${baseUrl}/api/dashboard/device-summary?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&deviceType=${overviewType}`)),
            (axios.get(`${baseUrl}/api/dashboard/overview-chart?mill_id=${selectedMill.mill_id}&date=${fromDate}&deviceType=${overviewType}`))
            ]

            Promise.all(promiseList).then((result) => {
                const [summary, chart] = result;

                console.log(summary)

                setSummary(summary && summary.data ? summary.data : {});

                const payload = constructChartData(chart && chart.data ? chart.data : {})
                setChartData(payload)
                setIsLoading(false);
            }).catch((error) => {
                console.log(error)
                setIsLoading(false);
            })
        }
    }, [selectedMill, fromDate, overviewType])

    const constructChartData = (data) => {
        let result = {};
        let series = [];
        let options = []
        if (data && data.chartOptions) {
            for (let i = 0; i < data.chartOptions.length; i++) {
                if (data.chartSeries[i] > 0) {
                    series.push(data.chartSeries[i])
                    options.push(data.chartOptions[i])
                }
            }
            result['chartOptions'] = options;
            result['chartSeries'] = series;
        }
        return result
    }

    const apiCall = async () => {
        await fetchSummaryInfo();
        await fetchChartInfo();
        // if (overviewType === 'conveyor') {
        //     await fetchConveyorChartInfo();
        //     setIsLoading(false);
        // } else {
        //     await fetchPlatFormChartInfo();
        //     setIsLoading(false);
        // }
        setIsLoading(false);
    }


    const fetchSummaryInfo = async () => {
        // const summaryData = await axios.get(`${baseUrl}/api/dashboard/platform-summary?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&deviceType=${overviewType}`);
        const summaryData = await axios.get(`${baseUrl}/api/dashboard/device-summary?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&deviceType=${overviewType}`);
        setSummary(summaryData.data ?? {});
    }

    const fetchChartInfo = async () => {
        const conveyorChartData = await axios.get(`${baseUrl}/api/dashboard/overview-chart?mill_id=${selectedMill.mill_id}&date=${fromDate}&deviceType=${overviewType}`);
        setChartData(conveyorChartData.data ?? {});
    }

    const fetchPlatFormChartInfo = async () => {
        //const platformChartData = await axios.get(`${baseUrl}/api/dashboard/platform-report-chart?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&deviceType=${overviewType}`);
        const platformChartData = await axios.get(`${baseUrl}/api/dashboard/overview-chart?mill_id=${selectedMill.mill_id}&date=${fromDate}&deviceType=${overviewType}`);
        //constructChartPayload(platformChartData.data, overviewType);
        setPlatformChart(platformChartData.data);
    }

    const fetchConveyorChartInfo = async () => {
        //const conveyorChartData = await axios.get(`${baseUrl}/api/dashboard/conveyor-overview-chart?mill_id=${selectedMill.mill_id}&date=${fromDate}&deviceType=${overviewType}`);
        const conveyorChartData = await axios.get(`${baseUrl}/api/dashboard/overview-chart?mill_id=${selectedMill.mill_id}&date=${fromDate}&deviceType=${overviewType}`);
        setConveyorChart(conveyorChartData.data);
    }

    const constructChartPayload = async (data, type) => {
        let chartSeriesArr = [];
        let chartOptionsArr = [];
        let datesLength = 0;
        if (data) {
            for (const i of Object.keys(data)) {
                let resultType = {};
                resultType['name'] = i;
                resultType['data'] = data[i].chartSeries;
                chartSeriesArr.push(resultType);
                console.log(`${datesLength}   ${data[i].chartOptions.length}`)
                if (datesLength < data[i].chartOptions.length) {
                    datesLength = data[i].chartOptions.length
                    chartOptionsArr = data[i].chartOptions;
                }
            }
            setPlatformChart({
                'chartSeries': chartSeriesArr,
                'chartOptions': chartOptionsArr
            });
        }
    }

    return (
        <div className="layout">
            <SnackbarProvider />
            <LoadingSpinner open={isLoading} />
            <MillSelectComponent selectedMillProps={selectedMill} page='OVERVIEW' callBack={(mill) => {
                setSelectedMill(mill);
            }} dateRangeCallBack={(from, to) => {
                setFromDate(from);
            }} />
            <br></br>
            <MillSummaryComponent page='OVERVIEW'
                summary={summary}
                onTypeChange={(value) => {
                    setOverviewType(value);
                }} />
            <br></br>
            <Stack direction={'row'} justifyContent='space-between' spacing={2}>
                <Stack direction={'column'} justifyContent='space-between' spacing={2} sx={{ width: '65%' }}>
                    <Card sx={{ borderRadius: '16px', border: 1, height: '100%' }} style={{ alignContent: 'center' }}>
                        <CardContent>
                            {/* {overviewType == 'platform' ? <FFBOverviewChart type={overviewType} platformChart={platformChart} /> :
                                <FFBOverviewChartConveyor type={overviewType} conveyorChart={conveyorChart} />} */}
                            {<FFBOverviewChart type={overviewType} chartData={chartData} />}

                        </CardContent>
                    </Card>
                    {/* <Card sx={{ borderRadius:'16px', border:1, height:'50%'}} style={{alignContent:'center'}}>
                        <CardContent>
                            <WeightOverviewChart type={overviewType}/>
                        </CardContent>
                    </Card> */}
                </Stack>
                <Stack direction={'column'} justifyContent='space-between' spacing={2} sx={{ width: '35%' }}>
                    <PlatformImageComponent type={overviewType} selectedDate={fromDate} selectedMill={selectedMill} summary={summary} />
                </Stack>
            </Stack>
        </div>
    );
}