import { useEffect, useState } from "react";
import "./millReportView.scss";
import { MillSelectComponent } from "../../components/MillSelectComponent";
import { PlatformReportComponent } from "../../components/Report/platform/PlatformReportComponent";
import { ConveyorReportComponent } from "../../components/Report/conveyor/ConveyorReportComponent";
import { Button, Stack } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useLocation, useNavigate } from "react-router-dom";
import { processDateRangeValue } from "../../util/util";
import axios from "axios";
import { baseUrl } from "../../util/config";
import moment from "moment";
import { PlatformTableComponent } from "../../components/Report/platform/PlatformTableComponent";
import { CONVEYOR, PLATFORM } from "../../constants/Constants";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { SnackbarProvider, enqueueSnackbar } from 'notistack';

export const MillReportView = () => {
  useEffect(() => {
    getDateRange();
  }, []);

  const navigate = useNavigate();
  const location = useLocation();
  const [selectedMill, setSelectedMill] = useState();
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [conveyorChart, setConveyorChart] = useState();
  const [tableData, setTableData] = useState([]);
  const [conveyorSummary, setConveyorSummary] = useState();
  const [platformSummary, setPlatformSummary] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isOerExists, setIsOerExists] = useState(false);
  const [conveyorSummaryP, setConveyorSummaryP] = useState({});
  const [platformReportData, setPlatformReportData] = useState([]);
  const [platformChart, setPlatformChart] = useState();

  let promiseList = [];
  useEffect(() => {
    if (!selectedMill && location.state && location.state.selectedMill) {
      setSelectedMill(location.state.selectedMill)
    }
    if (selectedMill && fromDate && toDate) {
      console.log(selectedMill, fromDate, toDate)
      setIsLoading(true);
      promiseList = [
        axios.get(
          `${baseUrl}/api/dashboard/device-summary?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=${PLATFORM}`
        ),
        axios.get(
          `${baseUrl}/api/dashboard/device-summary?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=${CONVEYOR}`
        ),
        axios.get(
          `${baseUrl}/api/dashboard/report-chart?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=${PLATFORM}`
        ),
        axios.get(
          `${baseUrl}/api/dashboard/report-chart?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=${CONVEYOR}`
        ),
        axios.get(
          `${baseUrl}/api/dashboard/platform-report-table?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=${PLATFORM}`
        )
      ]

      Promise.allSettled(promiseList).then(async (result) => {
        const [platformSummary, conveyorSummary, platformChart, conveyorChart, platformTable] = result;
        if (platformSummary.status === 'fulfilled') {
          setPlatformSummary(platformSummary.value && platformSummary.value.data ? platformSummary.value.data : {});
        } else {
          enqueueSnackbar(platformSummary.reason, { autoHideDuration: 3000 })
        }

        if (conveyorSummary.status === 'fulfilled') {
          setConveyorSummary(conveyorSummary.value && conveyorSummary.value.data ? conveyorSummary.value.data : {});
        } else {
          enqueueSnackbar(conveyorSummary.reason, { autoHideDuration: 3000 })
        }

        if (platformChart.status === 'fulfilled') {
          let payload = await constructChartPayload(platformChart.value && platformChart.value.data ? platformChart.value.data : {})
          setPlatformChart(payload);
        } else {
          enqueueSnackbar(platformChart.reason, { autoHideDuration: 3000 })
        }

        if (conveyorChart.status === 'fulfilled') {
          let payload = await constructChartPayload(conveyorChart.value && conveyorChart.value.data ? conveyorChart.value.data : {})
          setConveyorChart(payload);
        } else {
          enqueueSnackbar(conveyorChart.reason, { autoHideDuration: 3000 })
        }

        if (platformTable.status === 'fulfilled') {
          setTableData(platformTable.value && platformTable.value.data ? platformTable.value.data : []);
        } else {
          enqueueSnackbar(platformTable.reason, { autoHideDuration: 3000 })
        }

        setIsLoading(false);
      })
    }
  }, [selectedMill, fromDate, toDate]);


  const fetchPlatformChartInfo = async () => {
    const chartData = await axios.get(
      `${baseUrl}/api/dashboard/report-chart?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=${PLATFORM}`
    );
    let payload = await constructChartPayload(chartData.data ?? {})
    setPlatformChart(payload);
  }

  const fetchPlatformData = async () => {
    const platformData = await axios.get(
      `${baseUrl}/api/dashboard/platform-report-data?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=${PLATFORM}`
    );
    setPlatformReportData(platformData.data);
  }


  const fetchConveyorChartInfo = async () => {
    const conveyorChartData = await axios.get(
      `${baseUrl}/api/dashboard/report-chart?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=${CONVEYOR}`);
    let payload = await constructChartPayload(conveyorChartData.data ?? {});
    setConveyorChart(payload)
  }

  const fetchPlatformSummaryInfo = async () => {
    const summaryData = await axios.get(
      `${baseUrl}/api/dashboard/device-summary?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=${PLATFORM}`
    );
    setPlatformSummary(summaryData.data ?? {});
  }

  const fetchConveyorSummaryInfo = async () => {
    const summaryData = await axios.get(
      `${baseUrl}/api/dashboard/device-summary?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=${CONVEYOR}`
    );
    setConveyorSummary(summaryData.data ?? {});
  }

  const fetchPlatformTableInfo = async () => {
    const tableData = await axios.get(
      `${baseUrl}/api/dashboard/platform-report-table?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=platform`
    );
    setTableData(tableData.data ?? []);
  };


  const fetchConveyorSummary = async () => {
    const summaryData = await axios.get(
      `${baseUrl}/api/dashboard/summary-by-device?mill_id=${selectedMill.mill_id}&fromDate=${fromDate}&toDate=${toDate}&deviceType=${CONVEYOR}`
    );
    setConveyorSummaryP(summaryData.data);
  };

  const constructChartPayload = async (data) => {
    let chartSeriesArr = [];
    let chartOptionsArr = [];
    if (data) {
      for (const i of Object.keys(data)) {
        let resultType = {};
        let ignoreChartValue = data[i].chartSeries ? data[i].chartSeries.reduce((a, b) => {
          return a + b;
        }) : 0;
        console.log(i, ignoreChartValue);
        if (ignoreChartValue > 0) {
          resultType["name"] = i;
          resultType["data"] = data[i].chartSeries;
          if (i == 'oer_value') {
            setIsOerExists(true);
            resultType["type"] = 'area'
            resultType["color"] = '#e0e0e0'
          } else {
            resultType["type"] = 'line'
            if (i == 'Loose_Fruit_Volume') {
              resultType['color'] = 'rgb(129, 212, 250)'
            }
            if (i == 'Overripe')
              resultType['color'] = 'rgb(78, 205, 196)'
            if (i == 'Ripe')
              resultType['color'] = 'rgb(199, 244, 100)'
            if (i == 'Small')
              resultType['color'] = '#775DD0'
            if (i == 'Underripe')
              resultType['color'] = '#FEB019'
            if (i == 'Unripe')
              resultType['color'] = 'rgb(253, 106, 106)'
            if (i == 'Partheno')
              resultType['color'] = '#B46C4B'
          }
          chartSeriesArr.push(resultType);
          if (!data[i].chartOptions.includes(null)) {
            chartOptionsArr = [];
            chartOptionsArr = data[i].chartOptions;
          }
        }
      }
      return {
        chartSeries: chartSeriesArr,
        chartOptions: chartOptionsArr.map(row => {
          const date = row.split('T')[0].split('-')
          return `${date[2]}/${date[1]}/${date[0]}`
        }),
      }
    }
  };

  const subtractDays = (numOfDays, date = new Date()) => {
    return moment(date).subtract(numOfDays, "days").toDate();
  };

  const getDateRange = async () => {
    let range = await processDateRangeValue(
      subtractDays(7, new Date()),
      subtractDays(0, new Date())
    );
    setFromDate(range.fromDate);
    setToDate(range.toDate);
  };

  return (
    <div className="layout">
      <SnackbarProvider />
      <LoadingSpinner open={isLoading} />
      <Button
        variant="text"
        onClick={() => {
          navigate("/overview", {
            state: {
              'selectedMill': selectedMill
            }
          });
        }}
        disableElevation
      >
        <ArrowBackIosIcon></ArrowBackIosIcon> Back to Overview
      </Button>
      <br></br>
      <br></br>
      <MillSelectComponent
        page="REPORT"
        selectedMillProps={selectedMill}
        callBack={(mill) => {
          setSelectedMill(mill);
        }}
        dateRangeCallBack={(from, to) => {
          setFromDate(from);
          setToDate(to);
        }}
      />
      <br></br>
      {/* <MillSummaryComponent page="REPORT" summary={summary} />
      <br></br> */}
      <Stack direction={"row"} justifyContent="space-between" spacing={2}>
        <div style={{ width: "100%" }}>
          <PlatformReportComponent
            chartData={platformChart}
            summary={platformSummary}
            // platformChart={platformChart}
            // totalFFBs={summary && summary.Ripe ? summary.Ripe.totalFFBs : 0}
            truckCount={tableData && tableData.length ? tableData.length : 0}
            oer={isOerExists}
          // platfromSummaryP={platfromSummaryP}
          />
        </div>
        <div style={{ width: "100%" }}>
          <ConveyorReportComponent
            conveyorChart={conveyorChart} summary={conveyorSummary} oer={isOerExists}
          />
        </div>
      </Stack>
      <br></br>
      <PlatformTableComponent rows={tableData} fromDate={fromDate} toDate={toDate} mill={selectedMill} />
    </div>
  );
};
