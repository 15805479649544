import {
    Card,
    CardContent,
    Stack,
    Typography,
    Divider,
} from "@mui/material";
import React, { FC } from "react";
import { ImageSummary } from "../../models/ImageSummary";
import "./GradeDistributionCard.scss";

interface GradeDistributionCardProps {
    cardColor: string;
    fontSize: number;
    titleText: String;
    image_summary: ImageSummary;
    show_small?: boolean;
}

const GradeDistributionCard: FC<GradeDistributionCardProps> = ({
    cardColor,
    fontSize,
    titleText,
    image_summary,
    show_small

}) => {
    console.log("IMAGE_SUMMARY", image_summary)

    return (<div className="GradeDistributionCard">
        <Card
            sx={{ borderRadius: "16px" }}
            style={{ backgroundColor: cardColor, alignContent: "center" }}
        >
            <CardContent>
                <Stack direction={"row"} justifyContent="space-evenly">
                    {/* <div style={{ maxWidth: "15%" }}>
              <Typography
                sx={{ fontSize: fontSize, fontWeight: "bold" }}
                color="text.secondary"
                gutterBottom
              >
                {titleText}
              </Typography>
            </div> */}
                    <div>
                        <Typography
                            sx={{ fontSize: fontSize }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Unripe
                        </Typography>
                        <Typography variant="h4" component="div">
                            {image_summary && image_summary.unripe_count ? image_summary.unripe_count : 0}
                        </Typography>
                    </div>
                    <Divider
                        orientation="vertical"
                        light={true}
                        sx={{ backgroundColor: "#fff", width: "2px" }}
                        flexItem
                    ></Divider>
                    <div>
                        <Typography
                            sx={{ fontSize: fontSize }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Under-ripe
                        </Typography>
                        <Typography variant="h4" component="div">
                            {image_summary && image_summary.underripe_count ? Math.round(image_summary.underripe_count) : 0}
                        </Typography>
                    </div>
                    <Divider
                        orientation="vertical"
                        light={true}
                        sx={{ backgroundColor: "#fff", width: "2px" }}
                        flexItem
                    ></Divider>
                    <div>
                        <Typography
                            sx={{ fontSize: fontSize }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Ripe
                        </Typography>
                        <Typography variant="h4" component="div">
                            {image_summary && image_summary.ripe_count ? Math.round(image_summary.ripe_count) : 0}
                        </Typography>
                    </div>
                    <Divider
                        orientation="vertical"
                        light={true}
                        sx={{ backgroundColor: "#fff", width: "2px" }}
                        flexItem
                    ></Divider>
                    <div>
                        <Typography
                            sx={{ fontSize: fontSize }}
                            color="text.secondary"
                            gutterBottom
                        >
                            Over-ripe
                        </Typography>
                        <Typography variant="h4" component="div">
                            {image_summary && image_summary.overripe_count ? Math.round(image_summary.overripe_count) : 0}
                        </Typography>
                    </div>
                    {show_small && image_summary && image_summary.small_count ? <>
                        <Divider
                            orientation="vertical"
                            light={true}
                            sx={{ backgroundColor: "#fff", width: "2px" }}
                            flexItem
                        ></Divider><div>

                            <Typography
                                sx={{ fontSize: fontSize }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Small
                            </Typography>
                            <Typography variant="h4" component="div">
                                {image_summary.small_count ? Math.round(image_summary.small_count) : 0}
                            </Typography>
                        </div></>
                        : null}
                    {image_summary && image_summary.empty_count ? <>
                        <Divider
                            orientation="vertical"
                            light={true}
                            sx={{ backgroundColor: "#fff", width: "2px" }}
                            flexItem
                        ></Divider><div>

                            <Typography
                                sx={{ fontSize: fontSize }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Empty
                            </Typography>
                            <Typography variant="h4" component="div">
                                {image_summary && image_summary.empty_count ? Math.round(image_summary.empty_count) : 0}
                            </Typography>
                        </div></>
                        : null}
                    {image_summary && image_summary.partheno_count ? <>
                        <Divider
                            orientation="vertical"
                            light={true}
                            sx={{ backgroundColor: "#fff", width: "2px" }}
                            flexItem
                        ></Divider><div>

                            <Typography
                                sx={{ fontSize: fontSize }}
                                color="text.secondary"
                                gutterBottom
                            >
                                Partheno
                            </Typography>
                            <Typography variant="h4" component="div">
                                {image_summary && image_summary.partheno_count ? Math.round(image_summary.partheno_count) : 0}
                            </Typography>
                        </div></>
                        : null}
                </Stack>
            </CardContent>
        </Card>
    </div>
    )
};

export default GradeDistributionCard;
