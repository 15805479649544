import { Grid, Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useEffect, useState } from "react";
import { SimpleToggleButton } from "../common/SimpleToggleButton";

export const FFBOverviewChart = ({
  type,
  chartData
}) => {

  useEffect(() => {
    if (chartData && chartData.chartSeries) {
      setChartSeries([{
        data: chartData.chartSeries,
        name: 'count'
      }]);
      setChartOptions({
        colors: ['rgb(199, 244, 100)', 'rgb(253, 106, 106)', 'rgb(78, 205, 196)', '#FEB019', '#775DD0'],
        chart: {
          height: 350,
          type: 'bar',
          toolbar: {
            show: true
          },
          zoom: {
            enabled: false
          }
        },
        responsive: [{
          breakpoint: 480,
          options: {
            legend: {
              position: 'bottom',
              offsetX: 0,
              offsetY: 0
            }
          }
        }],
        //colors: colors,
        plotOptions: {
          bar: {
            columnWidth: '45%',
            distributed: true,
          }
        },
        dataLabels: {
          enabled: false
        },
        legend: {
          position: 'right',
          offsetY: 0
        },
        fill: {
          opacity: 1,
          // colors: ['rgb(199, 244, 100)', 'rgb(253, 106, 106)', 'rgb(78, 205, 196)', '#FEB019', '#775DD0']
        },
        xaxis: {
          categories: chartData.chartOptions ?? [],
          labels: {
            style: {
              //colors: colors,
              fontSize: '12px'
            }
          }
        }
      })
    }
  }, [chartData])


  const [chartDataType, setChartDataType] = useState('');
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState({});
  return (
    <>
      <Grid container>
        <Grid item sm={12}>
          <Stack direction={'row'} justifyContent='space-between' key={type + new Date()}>
            <Typography>Number of FFBs - {type}</Typography>
            {/* <SimpleToggleButton
              value1={'grade'}
              value2={'size'}
              text1={'By Grade'}
              text2={'By Size'}
              onHandleChange={(value) => {
              }}></SimpleToggleButton> */}
          </Stack>
          <br></br>
          <ReactApexChart options={chartOptions} series={chartSeries} type='bar'></ReactApexChart>
        </Grid>
      </Grid>
    </>
  );
}