import { Stack, Grid } from "@mui/material";
import React, { FC } from "react";
import { ImageSummary } from "../../models/ImageSummary";
import { SimpleCard } from "../common/SimpleCard";
import GradeDistributionCard from "../GradeDistributionCard/GradeDistributionCard";
import "./GradeCard.scss";
import GradeCountDistribution from "../GradeDistributionCard/GradeCountDistribution";

interface GradeCardProps {
  image_summary: ImageSummary;
  show_oer?: boolean;
  show_image_count?: boolean;
  image_count?: number;
  show_small?: boolean;
}

const GradeCard: FC<GradeCardProps> = ({ show_small, image_summary, show_oer, image_count, show_image_count }) => (
  <div className="GradeCard">
    <Stack direction={"row"} justifyContent="space-around">
      <Grid container spacing={2}>
        {show_oer ?
          show_image_count ?
            <Grid item sm={2}>
              <SimpleCard
                //border={1}
                fontSize={18}
                cardColor="#DEE7CC"
                titleText="Images Processed"
                value={image_count ? image_count : 0}
              ></SimpleCard>
            </Grid>


            :
            (
              <Grid item sm={2}>
                <SimpleCard
                  //border={1}
                  fontSize={18}
                  cardColor="#DEE7CC"
                  titleText="OER Value"
                  value="3.67%"
                ></SimpleCard>
              </Grid>
            )

          : null}
        <Grid item sm={3}>
          <SimpleCard
            //minWidth={300}
            fontSize={18}
            cardColor="#DEE7CC"
            titleText="No. of processed FFBs"
            value={
              image_summary && image_summary.total_fruit_count
                ? image_summary.total_fruit_count
                : 0
            }
          ></SimpleCard>
        </Grid>
        <Grid item sm={show_oer ? 7 : 9}>
          <GradeCountDistribution
            image_summary={image_summary}
            show_small={show_small}
            cardColor={"#DEE7CC"}
            fontSize={18}
            titleText={""}
          />
        </Grid>
      </Grid>
    </Stack>
  </div>
);

export default GradeCard;
