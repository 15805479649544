import axios from "axios";
import { AUTH_COOKIE } from "../constants/Constants";
import { baseUrl } from "../util/config";

export const AuthService = {
  isAuthenticated: () => {
    return !!localStorage.getItem(AUTH_COOKIE);
  },
  authenticate: async (
    userName: any,
    password: any,
    successCb: () => void,
    errorCallback: () => void
  ) => {
    try {
      const loginResult = await axios.post(`${baseUrl}/api/auth/login`, {
        username: userName,
        password: password,
      });
      if (loginResult.data && loginResult.data.access_token) {
        localStorage.setItem(
          AUTH_COOKIE,
          JSON.stringify(loginResult.data)
        );
        successCb();
      } else {
        errorCallback();
      }
    } catch (error) {
      console.error(error);
      errorCallback();
    }
  },
  tokenAuthenticate: async (
    token: any,
    successCb: () => void,
    errorCallback: () => void
  ) => {
    try {
      const loginResult = await axios.post(`${baseUrl}/api/auth/login`, {
        accessToken: token,
      });
      if (loginResult.data && loginResult.data.access_token) {
        localStorage.setItem(AUTH_COOKIE, loginResult.data);
        successCb();
      } else {
        errorCallback();
      }
    } catch (error) {
      console.error(error);
      errorCallback();
    }
  },
  signout() {
    localStorage.removeItem(AUTH_COOKIE);
  },
};
