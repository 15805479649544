import { Card } from "@mui/material";
import axios from "axios";
import { divIcon } from "leaflet";
import { useContext, useEffect, useState } from "react";
import { Marker, Popup, TileLayer, Map as LeafletMap } from "react-leaflet";
import { useNavigate } from "react-router-dom";
import AppContext from "../../components/common/store/AppContext";
import { baseUrl } from "../../util/config";
import './homePage.scss';


// const initialState = [];
// const { dispatch, useStoreState } = createStore(reducer, initialState);
let promiseList = [];
const HomePage = () => {
    const navigate = useNavigate();
    const [mills, setMills] = useState([]);
    const appContext = useContext(AppContext);
    useEffect(() => {
        fetchMilldata();
    }, [])

    const fetchMilldata = async () => {
        // const millData = await axios.get(`${baseUrl}/api/dashboard/mills`);
        // appContext.mills = millData.data ?? [];
        // setMills(millData.data);
        // localStorage.setItem('mills', JSON.stringify(millData.data))
        promiseList = [axios.get(`${baseUrl}/api/dashboard/mills`)]
        Promise.all(promiseList).then((result) => {
            const [millData] = result;
            appContext.mills = millData && millData.data ? millData.data : [];
            setMills(millData && millData.data ? millData.data : []);
            localStorage.setItem('mills', JSON.stringify(millData && millData.data ? millData.data : []))
        }).catch((error) => {
            console.log(error)
        })
    }

    const defaultLat = 1.323251;
    const defaultLong = 103.828369;

    return (
        <Card className="map-card">
            {/* <div className="map-style"> */}
            <LeafletMap className="map-style" center={mills[0] ? [mills[0].lat, mills[0].long] : [defaultLat, defaultLong]} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                    maxNativeZoom={19}
                    maxZoom={23}
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {mills.map((p) => {
                    return (
                        <Marker
                            key={p.mill_id}
                            position={[p.lat, p.long]}
                            icon={divIcon({
                                className: 'map-marker-pin'
                            })}
                            onclick={() => {
                                navigate("/overview", {
                                    state: {
                                        'selectedMill': p
                                    }
                                })
                            }}
                        >
                            <Popup>
                                {p.name}
                            </Popup>
                        </Marker>)
                })}

            </LeafletMap>
            {/* </div> */}

        </Card>

    );
}

export default HomePage;