import logo from './logo.svg';
import './App.css';
import Header from './components/Header/header';
import { Route, BrowserRouter, Routes } from "react-router-dom";
import { MillReportView } from './pages/reports/millReportView';
import { MillOverview } from './pages/overview/millOverview';
import HomePage from './pages/home/homePage';
import Login from './pages/login/Login';
import { configAuthHeaders, configRefreshToken } from './service/axiosConfigService';
import Platforms from './pages/Platforms/Platforms';
import { QueryClient } from "@tanstack/react-query";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { DAYS_IN_MILLISECONDS } from './constants/Constants';

function App() {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        cacheTime: DAYS_IN_MILLISECONDS * 10, // 2 days
      },
    },
  });
  const persister = createSyncStoragePersister({
    storage: window.localStorage,
  });
  configAuthHeaders();
  configRefreshToken();
  return (
    <PersistQueryClientProvider
      client={queryClient}
      persistOptions={{ persister }}
    >
    <BrowserRouter>
      <Routes>
      <Route path="/platforms"
      element={
        <Platforms/>
      }/>
      <Route path='/login'
         element={
          <div>
            <Login/>
          </div>
      }/>
      <Route path='/'
         element={
          <div>
            <Header>
              <HomePage/>
            </Header>
          </div>
        }/>
        <Route path='/report'
         element={
          <div>
            <Header>
              <MillReportView/>
            </Header>
          </div>
        }/>
        <Route path='/overview'
         element={
          <div>
            <Header>
              <MillOverview/>
            </Header>
          </div>
        }/>
      </Routes>
    </BrowserRouter>
    </PersistQueryClientProvider>
  );
}

export default App;
