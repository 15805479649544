import axios from "axios";
import { AUTH_COOKIE } from "../constants/Constants";
import { baseUrl } from "../util/config";
import { AuthService } from "./authService";

export const configAuthHeaders = () => {
  axios.interceptors.request.use(
    (config) => {
      const token = JSON.parse(localStorage.getItem(AUTH_COOKIE) ?? "{}");
      if (token && config?.headers) {
        config.headers["Authorization"] = `Bearer ${token.access_token}`;
        config.headers["timezone"] = new Date().getTimezoneOffset() * -1
      }
      return config;
    },
    (error) => Promise.reject(error)
  );
};

export const configRefreshToken = () => {
  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const { config } = error;
      const originalRequest = config;
      if (
        error.response &&
        error.response.status !== 498 &&
        error.response.status !== 403 &&
        error.response.status !== 401
      ) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
      if (
        ((error.response && error.response.status === 498) ||
          (error.response && error.response.status === 403) ||
          (error.response && error.response.status === 401)) &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true;
        const token =
          localStorage.getItem(AUTH_COOKIE) &&
          JSON.parse(localStorage.getItem(AUTH_COOKIE) ?? "{}");
        const refresh_token = token && token.refresh_token;
        const payload = {
          refresh_token: refresh_token
        }
        return axios
          .post(`${baseUrl}/api/auth/refresh`, payload)
          .then((res) => {
            if (res.status === 200) {
              localStorage.setItem(AUTH_COOKIE, JSON.stringify(res.data));

              originalRequest.headers.Authorization =
                "Bearer " + res.data.access_token;
              return new Promise((resolve) => {
                setTimeout(() => {
                  resolve(axios(originalRequest));
                }, 3000)
              })
            }
          });
      } else {
        window.location.href = "/login";
        return Promise.reject(error);
      }
    }
  );
};
