import { Stack } from "@mui/material";
import { SetStateAction, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import "./Login.scss";
import { RoundTextField } from "../../components/common/roundtextField/RoundTextField";
import { RoundedButton } from "../../components/common/roundedButton/RoundedButton";
import { AuthService } from "../../service/authService";
import MillGraderLogo from "../../components/common/logo/MillGraderLogo";

const Login = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  return (
    AuthService.isAuthenticated() ? 
    <Navigate
    to={{
      pathname: "/",
    }}
    /> :
    <div className="Login">
      <div className="login-container">
        <MillGraderLogo />
        <div className="login-form">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              setLoading(true);
              AuthService.authenticate(
                userName,
                password,
                () => {
                  console.log("navigate to home")
                  navigate("/");
                },
                () => setError(true)
              ).finally(() => setLoading(false));
            }}
          >
            <Stack direction="column" spacing={1}>
              <RoundTextField
                value={userName}
                onChange={(e: {
                  target: { value: SetStateAction<string> };  
                }) => {
                  setUserName(e.target.value);
                }}
                label="Username"
                type="text"
              ></RoundTextField>
              <RoundTextField
                label="Password"
                type="password"
                value={password}
                onChange={(e: {
                  target: { value: SetStateAction<string> };
                }) => {
                  setPassword(e.target.value);
                }}
              ></RoundTextField>

              <RoundedButton
                loading={loading}
                type="submit"
                label="Login"
              ></RoundedButton>
              {error && (
                <p className="error-msg">* Invalid username or password</p>
              )}
            </Stack>
          </form>
        </div>
      </div>
    </div> 
  );
};

export default Login;
