import axios from 'axios';
import React, { FC, useEffect } from 'react';
import { baseUrl } from '../../util/config';
import './Platforms.scss';
// import { useQuery } from "@tanstack/react-query";
import { Button, Stack, Typography } from '@mui/material';
import MillGraderLogo from '../../components/common/logo/MillGraderLogo';

interface PlatformsProps {}

const Platforms: FC<PlatformsProps> = () => {
  const [data, setData] = React.useState<any>(null)
  const [error, setError] = React.useState<boolean>(false)
  // const { isLoading: isLoading, data: data, error: error } =
  // useQuery(
  //   [
  //     `${baseUrl}/api/edge/serversByIpAddress`,
  //   ],
  //   async () => {
  //     return (await axios.post(`${baseUrl}/api/edge/serversByIpAddress`)).data;
  //   },
  //   {
  //     networkMode: "offlineFirst",
  //     // refetchOnMount: false,
  //     refetchOnWindowFocus: false,
  //     onError: (err: any) => {
  //       console.log("react-query-result", err.response?.data || err);
  //     },
  //   }
  // );

  useEffect(()=>{
    axios.post(`${baseUrl}/api/edge/serversByIpAddress`).then(res => setData(res.data)).catch(err=> {
      setError(true)
      console.log(err?.response)})
  }, [])

  return (
  <div className="Platforms">
    <div>
    <MillGraderLogo />
    <Typography variant='h5' sx={{marginTop: "5px"}} align={'center'}>
      Stations Available to you based on your network connection
    </Typography>
    </div>
    
    <Stack  direction="column">
    {data && data.map((row: any) => {
      console.log(row)
      return (<Button 
        key={row.server_id}
        sx={{margin: "10px"}}
        variant="outlined" onClick=
      {()=> {
        window.open(`http://${row.private_ip_address}:5000`, `${row.private_ip_address}`)
      }}>{row.server_name}</Button>)
      
    })}
    {error? <Typography variant='h6' sx={{marginTop: "5px"}} align={'center'} color={'error'}>
      No Available Stations
    </Typography>
    :null}
    </Stack>
    <Typography variant='h4' sx={{opacity:0}}>
      Available stations based on your connected network
    </Typography>
  </div>
)};

export default Platforms;
