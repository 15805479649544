import { Autocomplete, Box, Button, CircularProgress, IconButton, InputAdornment, Popover, Stack, TextField } from "@mui/material";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useContext, useEffect, useState } from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { useNavigate } from "react-router-dom";
import './MillSelectComponent.scss';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from "react-date-range";
import DateRangeIcon from '@mui/icons-material/DateRange';
import moment from "moment";
import axios from "axios";
import { baseUrl } from "../util/config";
import { useForm, Controller } from "react-hook-form";
import { isValidDate, processDateRangeValue, validateDateFormat } from "../util/util";
import AppContext from "./common/store/AppContext";

export const MillSelectComponent = ({
  selectedMillProps,
  page,
  callBack,
  dateRangeCallBack
}) => {
  useEffect(() => {
    millLoading = true;
    fetchMilldata();
    getDateRange();
  }, [selectedMillProps])

  const [date, setDate] = useState(new Date());
  const [displayCalendar, setDisplayCalendar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputValue, setInputValue] = useState("");
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [mill, setMill] = useState([]);
  const [selectedMill, setSelectedMill] = useState();
  const dateFormat = "YYYY-MM-DD";
  const { control } = useForm();
  const navigate = useNavigate();
  const appContext = useContext(AppContext);
  let millLoading = false;

  const onPopoverClose = (e, reason) => {
    setDisplayCalendar(false);
    setAnchorEl(null);
  };

  const onAdornmentClick = e => {
    setDisplayCalendar(true);
    setAnchorEl(e.currentTarget);
  };

  const subtractDays = (numOfDays, date = new Date()) => {
    return moment(date).subtract(numOfDays, "days").toDate();
  };

  const getDateRange = async () => {
    let range = await processDateRangeValue(
      subtractDays(7, new Date()),
      subtractDays(0, new Date())
    );
    let inputValue = "";
    if (range.fromDate) inputValue += moment(range.fromDate).format(dateFormat);
    if (range.toDate) inputValue += " - " + moment(range.toDate).format(dateFormat);
    const { fromDate, toDate } = processInputValue(inputValue);
    setFromDate(fromDate);
    setToDate(toDate);
    setInputValue(inputValue);
  };

  const onInputChange = e => {
    const inputValue = e.target.value;
    const { fromDate, toDate } = processInputValue(inputValue);
    dateRangeCallBack(moment(fromDate).format(dateFormat), moment(toDate).format(dateFormat));
    setInputValue(inputValue);
    setFromDate(fromDate);
    setToDate(toDate);
  };

  const fetchMilldata = async () => {
    let data = localStorage.getItem("mills");
    let millData = JSON.parse(data);
    setMill(millData);
    millLoading = false;
    setSelectedMill(selectedMillProps ?? millData[0])
  }

  const onSelectDateRanges = ({ selection }) => {
    let { startDate, endDate } = selection;

    startDate = moment(startDate);
    startDate = startDate.isValid() ? startDate.toDate() : undefined;

    endDate = moment(endDate);
    endDate = endDate.isValid() ? endDate.toDate() : undefined;

    let inputValue = "";
    if (startDate) inputValue += moment(startDate).format(dateFormat);
    if (endDate) inputValue += " - " + moment(endDate).format(dateFormat);

    dateRangeCallBack(moment(startDate).format(dateFormat), moment(endDate).format(dateFormat));
    setInputValue(inputValue);
    setFromDate(startDate);
    setToDate(endDate);
  };

  const processInputValue = (value) => {
    let [fromDate, toDate] = value.split(" - ").map(elm => elm.trim());

    fromDate = moment(fromDate, dateFormat);
    fromDate = fromDate.isValid() ? fromDate.toDate() : undefined;

    toDate = moment(toDate, dateFormat);
    toDate = toDate.isValid() ? toDate.toDate() : undefined;

    return { fromDate, toDate };
  }

  return (
    <Stack direction={'row'} justifyContent='space-between'>
      <Box sx={{ flexGrow: 1 }}>
        {millLoading && <CircularProgress size={20} color="inherit" />}
        {(mill.length > 0) && <Controller
          name={'autocomplete'}
          control={control}
          render={({ field: { onChange, value } }) => (
            <Autocomplete
              options={mill}
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              disableClearable
              id="combo-box-demo"
              sx={{ width: 300 }}
              {...(selectedMillProps && { defaultValue: selectedMillProps })}
              onChange={(event, newValue) => {
                callBack(newValue)
              }}
              renderInput={(params) => <TextField {...params} label="Choose a Mill" />}
            />
          )}
        />}

        {page === 'REPORT' ?
          <p>Summary report for selected mill</p> :
          <p>An overview of today's production</p>
        }

      </Box>

      {page === 'REPORT' ?
        <Box sx={{ flexGrow: 0, marginRight: 4 }}>
          <TextField
            label="Date Range"
            fullWidth={true}
            value={inputValue}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={onAdornmentClick}>
                    <DateRangeIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            onChange={onInputChange}
          />
          <Popover
            open={displayCalendar}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left"
            }}
            onClose={onPopoverClose}>
            <DateRange
              editableDateInputs={true}
              onChange={onSelectDateRanges}
              maxDate={new Date()}
              moveRangeOnFirstSelection={false}
              ranges={[
                {
                  startDate: fromDate,
                  endDate: toDate,
                  key: "selection"
                }
              ]}
              showDateDisplay={false} />
          </Popover>
        </Box> : <Box sx={{ flexGrow: 0, marginRight: 4 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className="date-picker"
              renderInput={(params) => (
                <TextField className="date-picker" {...params} />
              )}
              onChange={(date) => {
                if (isValidDate(moment(date).format(dateFormat))) {
                  setDate(date);
                  dateRangeCallBack(moment(date).format(dateFormat), moment(date).format(dateFormat));
                }
              }}
              value={date}></DatePicker>
          </LocalizationProvider>

          <Button className="view-report-button" variant="contained" onClick={() => {
            navigate('/report', {
              state: {
                'selectedMill': selectedMill
              }
            })
          }}>View Report</Button>
        </Box>}

    </Stack>
  );
}