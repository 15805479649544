import { Button, Card, CardContent, Divider, Grid, Stack, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { purple } from "@mui/material/colors";
import { styled } from "@mui/styles";
import { SimpleCard } from "../common/SimpleCard"
import DownloadIcon from '@mui/icons-material/Download';
import { useState } from "react";
import NorthEastIcon from '@mui/icons-material/NorthEast';
import SouthEastIcon from '@mui/icons-material/SouthEast';
export const MillSummaryComponent = ({
    page,
    summary,
    onTypeChange
}) => {
    const [toggle, setToggle] = useState('platform');
    const [isPlatform, setIsPlatform] = useState(true);

    const handleChange = (event, newAlignment) => {
        if (newAlignment !== null) {
            setIsPlatform(newAlignment === 'platform')
            setToggle(newAlignment);
            onTypeChange(newAlignment);
        }
    };

    const gradingDisCrad = (props) => {
        return (
            <Card sx={{ borderRadius: '16px' }} style={{ backgroundColor: props.cardColor, alignContent: 'center', height: '100%' }}>
                <CardContent>
                    <Stack direction={'row'} justifyContent='space-evenly'>
                        <div style={{ maxWidth: '15%' }}>
                            <Typography sx={{ fontSize: props.fontSize, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                                {props.titleText}
                            </Typography>
                        </div>
                        <div>
                            <Typography sx={{ fontSize: props.fontSize }} color="text.secondary" gutterBottom>
                                Unripe
                            </Typography>
                            <Typography variant="h5" component="div">
                                {summary && summary.Unripe ? summary.Unripe.percentage : 0}%
                            </Typography>
                        </div>
                        <Divider orientation="vertical" light={true} sx={{ backgroundColor: '#fff', width: '2px' }} flexItem></Divider>
                        <div>
                            <Typography sx={{ fontSize: props.fontSize }} color="text.secondary" gutterBottom>
                                Under-ripe
                            </Typography>
                            <Typography variant="h5" component="div">
                                {summary && summary.Underripe ? summary.Underripe.percentage : 0}%
                            </Typography>
                        </div>
                        <Divider orientation="vertical" light={true} sx={{ backgroundColor: '#fff', width: '2px' }} flexItem></Divider>
                        <div>
                            <Typography sx={{ fontSize: props.fontSize }} color="text.secondary" gutterBottom>
                                Ripe
                            </Typography>
                            <Typography variant="h5" component="div">
                                {summary && summary.Ripe ? summary.Ripe.percentage : 0}%
                            </Typography>
                        </div>
                        <Divider orientation="vertical" light={true} sx={{ backgroundColor: '#fff', width: '2px' }} flexItem></Divider>
                        <div>
                            <Typography sx={{ fontSize: props.fontSize }} color="text.secondary" gutterBottom>
                                Over-ripe
                            </Typography>
                            <Typography variant="h5" component="div">
                                {summary && summary.Overripe ? summary.Overripe.percentage : 0}%
                            </Typography>
                        </div>
                        {summary && summary.Small ? <>
                            <Divider orientation="vertical" light={true} sx={{ backgroundColor: '#fff', width: '2px' }} flexItem></Divider>
                            <div>
                                <Typography sx={{ fontSize: props.fontSize }} color="text.secondary" gutterBottom>
                                    Small
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {summary && summary.Small ? summary.Small.percentage : 0}%
                                </Typography>
                            </div>
                        </> : null}
                        {summary && summary.Empty ? <>
                            <Divider orientation="vertical" light={true} sx={{ backgroundColor: '#fff', width: '2px' }} flexItem></Divider>
                            <div>
                                <Typography sx={{ fontSize: props.fontSize }} color="text.secondary" gutterBottom>
                                    Empty
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {summary && summary.Empty ? summary.Empty.percentage : 0}%
                                </Typography>
                            </div>
                        </> : null}
                        {summary && summary.Partheno ? <>
                            <Divider orientation="vertical" light={true} sx={{ backgroundColor: '#fff', width: '2px' }} flexItem></Divider>
                            <div>
                                <Typography sx={{ fontSize: props.fontSize }} color="text.secondary" gutterBottom>
                                    Partheno
                                </Typography>
                                <Typography variant="h5" component="div">
                                    {summary && summary.Partheno ? summary.Partheno.percentage : 0}%
                                </Typography>
                            </div>
                        </> : null}

                    </Stack>
                    <Typography variant="h4" component="div">
                        {props.value}
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    const gradingDisCradV2 = (props) => {
        return (
            <Card sx={{ borderRadius: '16px' }} style={{ backgroundColor: props.cardColor, alignContent: 'center' }}>
                <CardContent>
                    <Stack direction={'row'} justifyContent='space-evenly'>
                        <div style={{ maxWidth: '15%' }}>
                            <Typography sx={{ fontSize: props.fontSize, fontWeight: 'bold' }} color="text.secondary" gutterBottom>
                                {props.titleText}
                            </Typography>
                        </div>
                        <div>
                            <Typography sx={{ fontSize: props.fontSize }} color="text.secondary" gutterBottom>
                                Unripe
                            </Typography>
                            <Typography variant="h4" component="div">
                                {summary && summary.unripe_percentage ? Math.round(summary.unripe_percentage) : 0}%
                            </Typography>
                        </div>
                        <Divider orientation="vertical" light={true} sx={{ backgroundColor: '#fff', width: '2px' }} flexItem></Divider>
                        <div>
                            <Typography sx={{ fontSize: props.fontSize }} color="text.secondary" gutterBottom>
                                Under-ripe
                            </Typography>
                            <Typography variant="h4" component="div">
                                {summary && summary.underripe_percentage ? Math.round(summary.underripe_percentage) : 0}%
                            </Typography>
                        </div>
                        <Divider orientation="vertical" light={true} sx={{ backgroundColor: '#fff', width: '2px' }} flexItem></Divider>
                        <div>
                            <Typography sx={{ fontSize: props.fontSize }} color="text.secondary" gutterBottom>
                                Ripe
                            </Typography>
                            <Typography variant="h4" component="div">
                                {summary && summary.ripe_percentage ? Math.round(summary.ripe_percentage) : 0}%
                            </Typography>
                        </div>
                        <Divider orientation="vertical" light={true} sx={{ backgroundColor: '#fff', width: '2px' }} flexItem></Divider>
                        <div>
                            <Typography sx={{ fontSize: props.fontSize }} color="text.secondary" gutterBottom>
                                Over-ripe
                            </Typography>
                            <Typography variant="h4" component="div">
                                {summary && summary.overripe_percentage ? Math.round(summary.overripe_percentage) : 0}%
                            </Typography>
                        </div>
                    </Stack>
                    <Typography variant="h4" component="div">
                        {props.value}
                    </Typography>
                </CardContent>
            </Card>
        );
    }

    const ColorButton = styled(Button)(({ theme }) => ({
        color: '#fff',
        backgroundColor: '#422AD9',
        '&:hover': {
            backgroundColor: purple[700],
        },
    }));

    return (
        <>
            <Stack direction={'row'} justifyContent='space-between'>
                <Typography variant="h5" component="div">
                    Summary
                </Typography>
                {page && page === 'REPORT' ?
                    <Button className="download-report-button" variant='contained' component='div'>
                        <DownloadIcon ></DownloadIcon>
                        Download Report
                    </Button> :
                    <ToggleButtonGroup
                        color="primary"
                        value={toggle}
                        exclusive
                        onChange={handleChange}>
                        <ToggleButton value='platform'>Platform</ToggleButton>
                        <ToggleButton value='conveyor'>Conveyor</ToggleButton>
                    </ToggleButtonGroup>
                }
            </Stack>
            <br></br>
            <Stack direction={'row'} justifyContent='space-around'>
                <Grid container spacing={2}>
                    {!isPlatform ?
                        <Grid item sm={3}>
                            <SimpleCard
                                //border={1}
                                fontSize={18}
                                cardColor='#DEE7CC'
                                titleText='OER Value'
                                value={summary && summary.avg_oer_value ? summary.avg_oer_value.toFixed(1) : "0.0"}
                            >
                                <>  </>
                                {/* {summary && summary.avg_oer_value && summary.avg_oer_value > 0 ? <NorthEastIcon color="success" sx={{ fontSize: 30 }} /> :
                                    <SouthEastIcon color="error" sx={{ fontSize: 30 }} />} */}
                            </SimpleCard>
                        </Grid>
                        : null}

                    {isPlatform ?
                        <Grid item sm={3}>
                            <SimpleCard
                                //minWidth={300}
                                fontSize={18}
                                cardColor='#DEE7CC'
                                titleText='No. of processed FFBs'
                                value={summary && summary.total_fruit_count ? summary.total_fruit_count : 0}
                            ></SimpleCard>
                        </Grid> : null
                    }
                    <Grid item sm={9}>
                        {gradingDisCradV2({ cardColor: '#DEE7CC', fontSize: 18, titleText: '' })}
                    </Grid>
                </Grid>
            </Stack>
        </>
    )
}