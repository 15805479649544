import { Grid, Stack, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { SimpleCard } from "../../common/SimpleCard"
import GradeDistributionCard from "../../GradeDistributionCard/GradeDistributionCard";
import moment from "moment";

export const PlatformReportComponent = ({
  truckCount,
  summary,
  chartData
}) => {

  // const [summary, setSummary] = useState({
  //   ripe: 0,
  //   unripe: 0,
  //   underRipe: 0,
  //   overRipe: 0,
  //   small: 0,
  //   empty: 0,
  //   partheno: 0
  // });
  const [totalFFBs, setTotalFFBs] = useState(0);
  const [chartSeries, setChartSeries] = useState([]);
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (chartData) {
      setChartSeries(chartData.chartSeries ?? []);
      setChartOptions({
        chart: {
          height: 350,
          type: 'line',
          zoom: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: 'Distributions - Platform',
          align: 'left'
        },
        grid: {
          row: {
            colors: ['transparent'], // takes an array which will be repeated on columns
            opacity: 0.5
          },
        },
        colors: ['#E91E63', '#66DA26', '#546E7A', '#2E93fA', '#FF9800', '#4a148c', '#e0e0e0'],
        xaxis: {
          categories: chartData.chartOptions ?? [],
          type: "datetime",
          labels: {
            format: 'dd/MM/yyyy'
          }
        },
        yaxis: [
          {
            seriesName: 'Ripe',
            axisTicks: {
              show: true,
            },
            axisBorder: {
              show: true,
              color: '#008FFB'
            },
            labels: {
              style: {
                colors: '#008FFB',
              }
            },
            title: {
              text: "FFB Grade count",
              style: {
                color: '#008FFB',
              }
            },
            tooltip: {
              enabled: true
            }
          },
          {
            seriesName: 'Ripe',
            show: false
          },
          {
            seriesName: 'Ripe',
            show: false
          },
          {
            seriesName: 'Ripe',
            show: false
          },
          {
            seriesName: 'Ripe',
            show: false
          }
        ]
      })
    }
  }, [chartData])

  // useEffect(() => {
  //   if (platformReportData) {
  //     const summaryTemp = {
  //       ripe: 0,
  //       unripe: 0,
  //       underRipe: 0,
  //       overRipe: 0,
  //       small: 0,
  //       empty: 0,
  //       partheno: 0
  //     }
  //     let totalFFBsTemp = 0;
  //     const options = [];
  //     const ripeSeries = {
  //       name: 'Ripe',
  //       data: [],
  //     }
  //     const unripeSeries = {
  //       name: 'Unripe',
  //       data: [],
  //     }
  //     const underripeSeries = {
  //       name: 'Underripe',
  //       data: [],
  //     }
  //     const overripeSeries = {
  //       name: 'Overripe',
  //       data: [],
  //     }
  //     const smallSeries = {
  //       name: 'Small',
  //       data: [],
  //     }
  //     const emptySeries = {
  //       name: 'Empty',
  //       data: [],
  //     }

  //     const parthenoSeries = {
  //       name: 'Partheno',
  //       data: [],
  //     }

  //     platformReportData.forEach(element => {
  //       const { image_date, Ripe, UnRipe, OverRipe, UnderRipe, Small, Empty, Partheno, totalFFBs } = element;
  //       summaryTemp.ripe += Ripe;
  //       summaryTemp.unripe += UnRipe;
  //       summaryTemp.underRipe += UnderRipe;
  //       summaryTemp.overRipe += OverRipe;
  //       summaryTemp.small += Small;
  //       summaryTemp.empty += Empty;
  //       summaryTemp.partheno += Partheno;
  //       totalFFBsTemp += totalFFBs;
  //       options.push(moment(image_date).format('DD/MM/YYYY'))
  //       ripeSeries.data.push(Ripe);
  //       unripeSeries.data.push(UnRipe);
  //       underripeSeries.data.push(UnderRipe);
  //       overripeSeries.data.push(OverRipe);
  //       smallSeries.data.push(Small);
  //       emptySeries.data.push(Empty);
  //       parthenoSeries.data.push(Partheno);
  //     });
  //     // summary to percentage
  //     summaryTemp.ripe = Math.round((summaryTemp.ripe / totalFFBsTemp) * 100);
  //     summaryTemp.unripe = Math.round((summaryTemp.unripe / totalFFBsTemp) * 100);
  //     summaryTemp.underRipe = Math.round((summaryTemp.underRipe / totalFFBsTemp) * 100);
  //     summaryTemp.overRipe = Math.round((summaryTemp.overRipe / totalFFBsTemp) * 100);
  //     summaryTemp.small = Math.round((summaryTemp.small / totalFFBsTemp) * 100);
  //     summaryTemp.empty = Math.round((summaryTemp.empty / totalFFBsTemp) * 100);
  //     summaryTemp.partheno = Math.round((summaryTemp.partheno / totalFFBsTemp) * 100);


  //     setSummary(summaryTemp);
  //     setTotalFFBs(totalFFBsTemp);
  //     console.log('summary', summaryTemp)
  //     const tempChartSeries = [overripeSeries, ripeSeries, underripeSeries, unripeSeries];
  //     if (summaryTemp.small > 0) {
  //       tempChartSeries.push(smallSeries);
  //     }
  //     if (summaryTemp.empty > 0) {
  //       tempChartSeries.push(emptySeries);
  //     }
  //     if (summaryTemp.partheno > 0) {
  //       tempChartSeries.push(parthenoSeries);
  //     }

  //     //setChartSeries(tempChartSeries);
  //     // setChartOptions({
  //     //   chart: {
  //     //     height: 350,
  //     //     type: 'line',
  //     //     zoom: {
  //     //       enabled: false
  //     //     }
  //     //   },
  //     //   dataLabels: {
  //     //     enabled: false
  //     //   },
  //     //   stroke: {
  //     //     curve: 'smooth'
  //     //   },
  //     //   title: {
  //     //     text: 'Number of FFBs - Platform',
  //     //     align: 'left'
  //     //   },
  //     //   grid: {
  //     //     row: {
  //     //       colors: ['transparent'], // takes an array which will be repeated on columns
  //     //       opacity: 0.5
  //     //     },
  //     //   },
  //     //   xaxis: {
  //     //     categories: options ?? [],
  //     //   }
  //     // })
  //   }
  // }, [platformReportData])

  return (
    <>
      <Typography variant="h5" component="div">
        Platform
      </Typography>
      <Stack direction={'column'} spacing={2}>
        <Stack direction={'row'}>
          <Grid container spacing={2}>
            <Grid item sm={6}>
              <SimpleCard
                border={0.1}
                fontSize={18}
                cardColor='#fff'
                titleText='No. of sample FFBs'
                value={summary && summary.total_fruit_count ? summary.total_fruit_count : 0}>
              </SimpleCard>
            </Grid>
            <Grid item sm={6}>
              <SimpleCard
                border={0.1}
                fontSize={18}
                cardColor='#fff'
                titleText='No. of Trucks'
                value={truckCount}>
              </SimpleCard>
            </Grid>
          </Grid>
        </Stack>
        <Grid container>
          <Grid item sm={12}>
            <div style={{ maxWidth: "100%" }}>
              <Typography
                sx={{ fontSize: "24", fontWeight: "bold" }}
                color="text.secondary"
                gutterBottom
              >
                {"Grading Distribution (%)"}
              </Typography>
            </div>
            <GradeDistributionCard
              image_summary={summary}
              show_small={summary && summary.small_count > 0}
              cardColor={"#DEE7CC"}
              fontSize={18}
              titleText={"(%)"}
            />

          </Grid>

        </Grid>
        <Grid container>
          <Grid item sm={12}>
            <ReactApexChart options={chartOptions} series={chartSeries} type='line'></ReactApexChart>
          </Grid>
        </Grid>
      </Stack>
    </>
  )
}