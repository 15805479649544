import { Typography } from "@mui/material";
import { ReactComponent as VulcanLogo } from "../../../assets/Vulcan.svg";
import "./MillGraderLogo.scss";

const MillGraderLogo = () => {
  return (
    <div className="MillGraderLogo">
      <VulcanLogo className="logo" />
      <Typography gutterBottom variant="h4" className="logo-text">
        <span className="work">Mill</span>
        <span className="smart">Grader</span>
      </Typography>
    </div>
  );
};

export default MillGraderLogo;
