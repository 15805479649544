import { Navigate, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Tooltip from "@mui/material/Tooltip";
import SettingsIcon from "@mui/icons-material/Settings";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import {MenuItem} from "@mui/material";
import './header.scss'
import { AuthService } from "../../service/authService";

export default function Header({ children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    AuthService.signout();
    navigate("/login");
  }
  return (
    AuthService.isAuthenticated() ? 
    <div className="Header">
      <AppBar position="static" className="top-menu">
        <Container maxWidth="xxl">
          <Toolbar disableGutters>
            <Typography
              className="logo-title"
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: { xs: "none", md: "flex" },
                fontFamily: "Roboto",
                fontWeight: 300,
                letterSpacing: ".2rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
                Mill Grader
            </Typography>

            <Typography component='a' onClick={() => {
              navigate("/overview")
            }} sx={{ flexGrow: 0, marginRight: 4, marginLeft: 1, color: "inherit", textDecoration: "none"}}>
              Overview
            </Typography>
            <Typography component='a' onClick={() => {
              navigate('/report')
            }} sx={{ flexGrow: 0, marginRight: 4, marginLeft: 1, color: "inherit", textDecoration: "none"}}>
              Reports
            </Typography>

            <Box
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
            ></Box>
            {/* <Box sx={{ flexGrow: 0, marginRight: 4 }}>
              <Tooltip title="Open settings">
                <SettingsIcon sx={{ fontSize: 30 }}></SettingsIcon>
              </Tooltip>
            </Box> */}
            <Box sx={{ flexGrow: 0, margin: 2 }}>
              <Tooltip title="User Profile">
                <AccountCircleIcon sx={{ fontSize: 30 }} onClick={handleClick}></AccountCircleIcon>
              </Tooltip>
            </Box>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
              <MenuItem onClick={logout}>Logout</MenuItem>
            </Menu>
          </Toolbar>
        </Container>
      </AppBar>
      <Container maxWidth="xl">{children}</Container>
    </div> : 
    <Navigate
      to={{
        pathname: "/login",
      }}
    />
  );
}