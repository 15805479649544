import { Box, Button, Card, CardContent, Divider, Grid, Stack, Typography } from "@mui/material";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Suspense, useEffect, useState } from "react";
import axios from "axios";
import { baseUrl } from '../../../util/config';
import { getJpegImage } from "../../../util/util";
import NoImageFound from "../../../assets/no_image_found.jpg";

export const PlatformImageComponent = ({
    type,
    selectedDate,
    selectedMill,
    summary
}) => {
    const [imageIndex, setImageIndex] = useState(0);
    const [imageList, setImageList] = useState([]);
    const imageButtonStyle = {
        minWidth: 0,
        padding: 0
    }

    useEffect(() => {
        if (selectedMill && selectedDate && type === 'platform') {
            axios.get(`${baseUrl}/api/image/gradedImages?mill_id=${selectedMill.mill_id}&fromDate=${selectedDate}&deviceType=${type}`).then(res => {
                if (res && res.data && res.data.mask) {
                    setImageList(res.data.mask)
                }
            }
            );
        }
    }, [selectedDate, selectedMill, type])



    const conveyorCard = (props) => {
        return (
            <Card sx={{ borderRadius: '16px' }} style={{ backgroundColor: '#303E14', alignContent: 'center' }}>
                <CardContent>
                    {/* <Stack direction={'row'} justifyContent='space-evenly'> */}
                    {/* <div> */}
                    {/* <Typography sx={{ fontSize: 18 }} color="#fff" gutterBottom>
                            {props.title}
                        </Typography>
                        <Typography variant="h4" color="#fff" component="div">
                            {props.value}
                        </Typography> */}
                    {/* </div> */}
                    {/* </Stack> */}
                    <Grid container
                        alignItems="center"
                        justifyContent="center"
                        spacing={4}
                        direction={'row'}>
                        <Grid item sm={6}>
                            <Typography variant="h5" color="#fff" gutterBottom>
                                {props.title}
                            </Typography>
                        </Grid>
                        <Grid item sm={6}>
                            <Typography variant="h3" color="#fff" component="div">
                                {props.value}
                            </Typography>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        );
    }

    const onForwardClick = () => {
        if (imageIndex + 1 === imageList.length) {
            setImageIndex(0)
        } else {
            setImageIndex(imageIndex + 1)
        }
    }

    const onBackwardClick = () => {
        if (imageIndex - 1 === -1) {
            setImageIndex(imageList.length - 1);
        } else {
            setImageIndex(imageIndex - 1)
        }
    }

    return (
        <>
            <Stack direction={'column'} spacing={2}>
                {type === 'conveyor' ? <>
                    {conveyorCard({ title: 'Loose Fruit Volume (mᶟ)', value: summary && summary.loose_fruit_volume ? summary.loose_fruit_volume : 0 })}
                    {conveyorCard({ title: 'Total Fruit Count', value: summary && summary.total_fruit_count ? summary.total_fruit_count : 0 })}
                </> :
                    <Card sx={{ borderRadius: '16px', border: 1 }} style={{ alignContent: 'center', height: '500px' }}>
                        <CardContent>
                            <Typography variant="h5" component="div">
                                Images & Videos
                            </Typography>
                            <Stack direction={'row'} justifyContent='space-between'>
                                <p>{selectedDate} </p>
                                {/* <Button>Refresh</Button> */}
                            </Stack>
                            <br></br>
                            <Stack direction={'row'} spacing={1}>
                                <Button style={imageButtonStyle} onClick={onBackwardClick}><ChevronLeftIcon></ChevronLeftIcon></Button>
                                <Suspense>
                                    <Box
                                        component="img"
                                        sx={{
                                            height: '100%',
                                            width: '85%'
                                        }}
                                        alt=""
                                        onError={(e) => {
                                            e.target.src = NoImageFound;
                                            e.target.onError = null; // prevents looping
                                        }}
                                        src={imageList && imageList.length > 0 ? imageList[imageIndex] : NoImageFound}
                                    />
                                </Suspense>
                                <Button style={imageButtonStyle} onClick={onForwardClick}><ChevronRightIcon></ChevronRightIcon></Button>
                            </Stack>
                        </CardContent>
                    </Card>
                }
            </Stack>
        </>
    );
}