import { Card, CardContent, Typography } from "@mui/material"

export const SimpleCard = (
   props
) => {
    return(
        <Card sx={{ borderRadius:'16px', border:props.border}} style={{backgroundColor:props.cardColor, alignContent:'center'}}>
            <CardContent>
            <Typography sx={{ fontSize: props.fontSize }} color="text.secondary" gutterBottom>
                {props.titleText}
            </Typography>
            <Typography variant="h4" component="div">
                {props.value}
                {props.children}
            </Typography>
            </CardContent>
        </Card>
    )
}